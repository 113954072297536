'use strict';

window.site = window.site || {};

/**
 * Utilities for interacting with the calendar.
 * @class Calendar
 * @static
 */
site.Calendar = function Calendar($) {

  'use strict';

  /**
   * Has the application been initialized?
   * @private
   */

  var inited = false;

  /**
   * Current javascript date and server datetime.
   * @private
   */
  var date = null;
  var datetime = null;

  /**
   * Calendar container.
   * @private
   */
  var $calendar = $('.calendar__plugin');

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    console.log('[Init calendar.js]');

    // Get server datetime and set current date
    datetime = site.App.config('datetime');
    date = datetime ? new Date(datetime) : new Date();

    // Initialize calendar
    if ($calendar.length) {
      initCalendar();
    }

    // Return success
    return true;
  };

  /**
   * Initialize calendar plugin.
   * @private
   */
  var initCalendar = function initCalendar() {
    $calendar.zabuto_calendar({

      language: site.App.config('locale'),
      show_previous: true,
      show_next: true,
      today: true,
      weekstartson: 0,

      data: calendarData,

      action_nav: function action_nav() {
        var to = $('#' + this.id).data('to');

        // Timeout to set past days after each click
        setTimeout(function () {
          var days = $('.day', $calendar);
          return formatDays(to.year, to.month, days);
        }, 5);
      }

    });

    // Format current days of the month
    formatDays();
  };

  /**
   * Format days.
   * @private
   */
  var formatDays = function formatDays(year, month, days) {

    var past = true;

    days = days || $('.day', $calendar);
    year = year || date.getFullYear();
    month = month || date.getMonth() + 1;

    days.each(function () {

      if (month <= date.getMonth() + 1 && year <= date.getFullYear()) {

        var $this = $(this);

        // Highlight today
        if ($('.badge-today', $this).length) {
          past = false;
          $this.addClass('today');
        }

        // Gray out past days
        if (past) {
          $this.addClass('past');
        }
      }
    });
  };

  /**
   * Expose public methods & properties.
   */
  return {
    init: init
  };
}(jQuery);
'use strict';

window.site = window.site || {};

/**
 * Init Mobile Menu related scripts
 * @class Site
 * @static
 */
site.Split001 = function Split001() {
  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    var $slider = [].slice.call(document.querySelectorAll('.split001 .gallery-slides'));

    if ($slider) {
      setTimeout(function () {
        $slider.forEach(function ($slide) {
          var flkty = new Flickity($slide, {
            // options
            cellAlign: 'left',
            contain: true,
            pageDots: false,
            imagesLoaded: true,
            autoPlay: 5000,
            wrapAround: true,
            lazyLoad: true,
            resize: true,
            adaptiveHeight: true,
            groupCells: false
          });
        });
      }, 200);
    }

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();
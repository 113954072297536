'use strict';

// Globals and dependencies
/* global Vue */
/* global loadjs */

loadjs.ready(['vue'], {
  success: function success() {
    if (!document.querySelector('#site-container')) {
      return false;
    }

    var init = new Vue({ el: '#site-container' });

    return init;
  }
});
'use strict';

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

/* global site */

window.site = window.site || {};

/**
 * Main application class.
 * @class App
 * @static
 */
window.site.App = function App() {
  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Application config defaults.
   * @private
   * @param config.env     Current server environment
   * @param config.csrf    Security token to submit with forms
   * @param config.csrfName    Security token to submit with forms
   * @param config.locale  Current locale short code
   * @param config.device  Device detection based on browser signature
   * @param config.preview Page is shown through live preview mode
   * @param config.general Settings from general config
   */
  var config = {
    env: 'production',
    csrf: null,
    csrfName: null,
    locale: 'en',
    device: 'desktop',
    preview: false,
    datetime: null,
    school: {
      title: 'Commission scolaire de Laval',
      color_1: '#173C68',
      color_2: '#FF9100',
      address: '955 Boulevard Saint-Martin O',
      city: 'Laval',
      postal: 'H7S 1M5',
      lat: 45.60665,
      lng: -73.71241
    },
    general: {}
  };

  /**
   * Initializes the class.
   * @public
   */
  var init = function init(options) {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    // Store application settings
    options = options || {};

    if (options.env) {
      config.env = options.env;
    }
    if (options.csrf) {
      config.csrf = options.csrf;
    }
    if (options.csrfName) {
      config.csrfName = options.csrfName;
    }
    if (options.locale) {
      config.locale = options.locale;
    }
    if (options.device) {
      config.device = options.device;
    }
    if (options.preview) {
      config.preview = options.preview;
    }
    if (options.general) {
      config.general = options.general;
    }
    if (options.datetime) {
      config.datetime = options.datetime;
    }

    if (options.school) {

      if (options.school.title) {
        config.school.title = options.school.title;
      }
      if (options.school.color_1) {
        config.school.color_1 = options.school.color_1;
      }
      if (options.school.color_2) {
        config.school.color_2 = options.school.color_2;
      }
      if (options.school.address) {
        config.school.address = options.school.address;
      }
      if (options.school.city) {
        config.school.city = options.school.city;
      }
      if (options.school.postal) {
        config.school.postal = options.school.postal;
      }
      if (options.school.lat) {
        config.school.lat = options.school.lat;
      }
      if (options.school.lng) {
        config.school.lng = options.school.lng;
      }
    }

    // Initialize child classes
    // if (typeof site.ShareButton === 'object') { site.ShareButton.init(); }
    // if (typeof site.AjaxPagination === 'object') { site.AjaxPagination.init(); }
    // if (typeof site.MenuMobile === 'object') { site.MenuMobile.init(); }
    // if (typeof site.CookiePolicy === 'object') { site.CookiePolicy.init(); }
    // if (typeof site.ContactPage === 'object') { site.ContactPage.init(); }
    // if (typeof site.Hero === 'object') { site.Hero.init(); }

    // if (typeof site.Showroom === 'object') { site.Showroom.init(); }

    if (_typeof(site.Map) === 'object') {
      site.Map.init();
    }
    // if (typeof site.GeolocationServices === 'object') { site.GeolocationServices.init(); }

    // Blocks
    // if (typeof site.Split001 === 'object') { site.Split001.init(); }
    // if (typeof site.Slider001 === 'object') { site.Slider001.init(); }
    // if (typeof site.Slider002 === 'object') { site.Slider002.init(); }
    // if (typeof site.Slider003 === 'object') { site.Slider003.init(); }
    // if (typeof site.Slider004 === 'object') { site.Slider004.init(); }
    // if (typeof site.Grid002 === 'object') { site.Grid002.init(); }
    // if (typeof site.scrollTo === 'object') { site.scrollTo.init(); }
    // if (typeof site.Track === 'object') { site.Track.init(); }

    // if (typeof window.site.PlayGround === 'object') {window.site.PlayGround.init(); }

    // CSDL

    if (_typeof(site.Calendar) === 'object') {
      site.Calendar.init();
    }
    if (_typeof(site.Form) === 'object') {
      site.Form.init();
    }
    if (_typeof(site.Menu) === 'object') {
      site.Menu.init();
    }
    if (_typeof(site.Modal) === 'object') {
      site.Modal.init();
    }
    if (_typeof(site.Search) === 'object') {
      site.Search.init();
    }
    if (_typeof(site.Slider) === 'object') {
      site.Slider.init();
    }
    if (_typeof(site.Zoom) === 'object') {
      site.Zoom.init();
    }

    console.table(options);

    // Return success
    return true;
  };

  /**
   * Getter for application config.
   * @public
   */
  var getConfig = function getConfig(option) {
    return config[option] ? config[option] : false;
  };

  /**
   * Expose public methods & properties.
   */
  return {
    init: init,
    config: getConfig
  };
}();
'use strict';

window.site = window.site || {};

/**
 * Utilities for interacting with the menu.
 * @class Menu
 * @static
 */
site.Menu = function Menu($) {

  'use strict';

  /**
   * Has the application been initialized?
   * @private
   */

  var inited = false;

  /**
   * jQuery elements.
   * @private
   */
  var $menu = $('.header');
  var $burger = $('.toolbar__burger');
  var $layout = $('.header__layout');
  var $close = $('.header__closemenu');
  var $menuToggler = $('[action-toggle-menu]');

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    // Initialize menu
    initMenu();

    // Return success
    return true;
  };

  /**
   * Initialize menu.
   * @private
   */
  var initMenu = function initMenu() {

    console.log('[Init menu.js]');

    var $bodyObj = document.querySelector('body');
    var $burgerObj = document.querySelector('.toolbar__burger');
    var $closeObj = document.querySelector('.header__closemenu');
    var $menuObj = document.querySelector('.header');

    // Open menu
    $burgerObj.addEventListener('click', function (e) {
      e.preventDefault();

      $menuObj.classList.add('header--open');
      $bodyObj.style.overflow = 'hidden';
    });

    // Close menu
    $closeObj.addEventListener('click', function (e) {
      e.preventDefault();

      $menuObj.classList.remove('header--open');
      $bodyObj.style.overflow = 'auto';
    });

    console.log('menu length ' + $menuToggler.length);
    console.log('test length' + $('#site-container').length);

    $('#site-container').on('click', function (e) {
      console.log('hello');
    });

    $menuToggler.on('click', function (e) {
      e.preventDefault();

      console.log('toggling');

      var $parent = $(this).parents('.has-menu');

      console.log($parent);

      if ($parent.hasClass('menu__firstlevel--active')) {
        $parent.removeClass('menu__firstlevel--active');
        $parent.children('ul').slideUp();
      } else {
        $parent.addClass('menu__firstlevel--active');
        $parent.children('ul').slideDown();
      }
    });
  };

  /**
   * Expose public methods & properties.
   */
  return {
    init: init
  };
}.bind(site, jQuery)();
'use strict';

// Globals and dependencies
/* global Vue */
/* global loadjs */
/* global inViewport */

loadjs.ready(['vue'], {
  success: function success() {
    // Define a new component called ConditionalElement
    Vue.component('conditional-element', {
      props: ['whenIsHidden'],

      data: function data() {
        return {
          isVisible: false
        };
      },

      mounted: function mounted() {
        window.addEventListener('scroll', undefined.checkDisplay, { passive: true });
      },

      methods: {
        checkDisplay: function checkDisplay() {
          this.isVisible = !inViewport(document.querySelector(this.whenIsHidden));
        }
      },
      template: '\n        <transition name="fade">\n          <div v-show="isVisible">\n            <slot></slot>\n          </div>\n        </transition>\n      '
    });
  }
});
'use strict';

// Globals and dependencies
/* global Vue */
/* global loadjs */

loadjs.ready(['vue'], {
  success: function success() {
    // Define a new component called next-step-btn
    Vue.component('next-step-btn', {
      props: ['content', 'startingText', 'inbetweenText', 'whenIsEndedText'],

      data: function data() {
        return {
          step: 0,
          currentBtnText: '',
          identifier: null
        };
      },

      mounted: function mounted() {
        this.currentBtnText = this.startingText;
        this.identifier = this._uid;
      },

      methods: {
        nextStep: function nextStep() {
          var _this = this;

          this.step = this.step + 1;

          setTimeout(function () {
            _this.scroll('.site-intro p:nth-child(' + _this.step + ')');
          }, 355);

          if (this.step >= 1) {
            this.currentBtnText = this.inbetweenText;
          }
        },
        scroll: function scroll(selector) {
          document.querySelector(selector).scrollIntoView({ behavior: 'smooth' });
        }
      },
      template: '\n        <div class="">\n          <transition name="fade" v-for="(item, index) in content" :key="index">\n            <p\n              class="regular-text "\n              v-show="step >= index"\n              v-html="item.text"\n             ></p>\n          </transition>\n\n          <a href="#" v-if="step <= 1" class="site-btn" @click.prevent="nextStep" v-text="currentBtnText"></a>\n          <a href="#" v-if="step >= 2" class="site-btn" @click.prevent="scroll(\'#what-i-do\')" v-text="whenIsEndedText"></a>\n        </div>\n      '
    });
  }
});
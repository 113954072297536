'use strict';

// Globals and dependencies
/* global Vue */
/* global loadjs */
/* global inViewport */

loadjs.ready(['vue'], {
  success: function success() {
    // Define a new component called rainbow-lines-bar
    Vue.component('rainbow-lines-bar', {
      props: [],

      data: function data() {
        return {};
      },

      mounted: function mounted() {
        var _this = this;

        inViewport(this.$el, function () {
          _this.interval = setTimeout(_this.startAnimation, 20);
        });
      },

      methods: {
        startAnimation: function startAnimation() {
          this.$el.classList.add('is-animated');
        }
      },
      template: '\n        <div class="rainbow-lines-bar">\n          <div class="rainbow-line background-dark"></div>\n          <div class="rainbow-line background-accent1"></div>\n          <div class="rainbow-line background-accent2"></div>\n          <div class="rainbow-line background-accent3"></div>\n          <div class="rainbow-line background-accent4"></div>\n          <div class="rainbow-line background-accent5"></div>\n          <div class="rainbow-line background-accent6"></div>\n        </div>\n      '
    });
  }
});
'use strict';

window.site = window.site || {};

/**
 * Init Playground related scripts
 * @class Site
 * @static
 */
window.site.PlayGround = function () {
  /**
   * Js elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    // console.log('Playground();');

    var $items = document.querySelectorAll('.nodeList-items');

    $items.forEach(function (el) {
      // console.log(item);
      var item = el;
      item.style.color = 'red';
    });

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();
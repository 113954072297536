'use strict';

// Globals and dependencies
/* global Vue */
/* global loadjs */

loadjs.ready(['vue'], {
  success: function success() {
    // Define a new component called accordion-item
    Vue.component('inline-expender', {
      props: {
        title: {},
        desc: {}
      },

      data: function data() {
        return {
          isExpended: false,
          target: null
        };
      },

      mounted: function mounted() {
        setTimeout(function () {
          document.querySelectorAll('.blog-item').forEach(function (item) {
            item.setAttribute('style', 'height: ' + item.offsetHeight + 'px;');
            item.setAttribute('data-original-height', item.offsetHeight);
          });
        }, 1375);
      },

      methods: {
        toggleIsExpend: function toggleIsExpend(event) {
          this.isExpended = !this.isExpended;

          var $target = event.target;
          if ($target.tagName !== 'A') {
            $target = $target.closest('.blog-item');
          }

          this.target = $target;
        }
      },

      watch: {
        isExpended: function isExpended(_isExpended) {
          var _this = this;

          setTimeout(function () {
            var $expendedItem = _this.target;
            var $expenderBlock = _this.target.querySelector('.inline-expender-block');
            var expendedItemOriginalHeight = parseInt(_this.target.getAttribute('data-original-height'), 10);

            if (_isExpended) {
              $expendedItem.style.height = $expendedItem.offsetHeight + $expenderBlock.offsetHeight + 'px';
              $expenderBlock.style.marginTop = expendedItemOriginalHeight + 10 + 'px';
              // console.log($expendedItem.offsetHeight, $expenderBlock.offsetHeight);
            } else {
              $expendedItem.style.height = expendedItemOriginalHeight + 'px';
            }
          }, 355);
        }
      },

      template: '\n        <a @click.prevent.stop.capture="toggleIsExpend" :class="{ \'is-expended\': isExpended }">\n          <slot></slot>\n\n          <transition name="fade-delay">\n            <article v-show="isExpended" class="inline-expender-block" >\n              <div class="inline-expender-block-innder">\n                <h3 v-text="title">...</h3>\n                <p v-text="desc">...</p>\n              </div>\n            </article>\n          </transition>\n        </a>\n      '
    });
  }
});
'use strict';

window.site = window.site || {};

/**
 * Utilities for interacting with the search.
 * @class Search
 * @static
 */
site.Search = function Search($) {

  'use strict';

  /**
   * Has the application been initialized?
   * @private
   */

  var inited = false;

  /**
   * jQuery elements.
   * @private
   */
  var $body = $('body');

  var $searchLayout = $('.search__layout');
  var $searchModule = $('.search__module');
  var $searchInput = $('input[type="search"]', $searchModule);

  var $openButton = $('.toolbar__searchButton');
  var $closeButton = $('.search__module__close', $searchModule);

  var $typeahead = $('.typeahead', $searchModule);

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    // Initialize
    initSearch();

    $openButton.on('click', function () {
      openSearch();
    });

    $closeButton.on('click', function () {
      closeSearch();
    });

    // Return success
    return true;
  };

  /**
   * Initialize typeahead plugin.
   * @private
   */
  var initSearch = function initSearch() {

    var articleSource = new Bloodhound({
      remote: {
        url: '/recherche?q=%QUERY',
        wildcard: '%QUERY'
      },
      datumTokenizer: Bloodhound.tokenizers.obj.whitespace('label'),
      queryTokenizer: Bloodhound.tokenizers.whitespace
    });

    $typeahead.typeahead({
      hint: true,
      highlight: true,
      minLength: 1,
      limit: 5
    }, {
      name: 'articles',
      source: articleSource.ttAdapter(),
      displayKey: 'label',

      templates: {
        suggestion: function suggestion(data) {
          return '<a href="' + data.value + '">' + data.label + '</a>';
        }
      }

    });
  };

  /**
   * Open search bar.
   * @public
   */
  var openSearch = function openSearch() {

    $body.css('overflow', 'hidden');
    $searchLayout.fadeIn(400);
    $searchModule.slideDown(400);
    $searchInput.focus();
  };

  /**
   * Close search bar.
   * @public
   */
  var closeSearch = function closeSearch() {

    $body.css('overflow', 'auto');
    $searchLayout.fadeOut(400);
    $searchModule.slideUp(400);
  };

  /**
   * Expose public methods & properties.
   */
  return {
    init: init,
    open: openSearch,
    close: closeSearch
  };
}(jQuery);
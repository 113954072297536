'use strict';

window.site = window.site || {};

/**
 * Utilities for forms.
 * @class Form
 * @static
 */
site.Form = function Form($) {

  'use strict';

  /**
   * Has the application been initialized?
   * @private
   */

  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    // Initialize styled form input
    initRadio();
    initCheckbox();

    // Form submit event
    $('.js-article-form').on('submit', function (e) {
      e.preventDefault();
      submitForm($(this));
    });

    // Return success
    return true;
  };

  /**
   * Submit a form through ajax and show validation errors.
   * @public
   */
  var submitForm = function submitForm($form) {

    // Disable button
    var $button = $('button', $form);
    $button.prop('disabled', true);

    // Remove all errors
    $('.input-wrapper', $form).removeClass('input-error');

    // Send form data through an ajax post
    var request = $.post({
      url: '/',
      data: $form.serialize(),
      cache: false
    });

    // Request done
    request.always(function (response) {

      // Show success button
      $button.addClass('button--success');

      // Reset form after 3 seconds
      setTimeout(function () {
        $form[0].reset();
        $('.radio-option .radio', $form).removeClass('checked');
        $('.checkbox-option .checkbox', $form).removeClass('checked');
      }, 3000);
    });

    // Enable button after 3 seconds
    setTimeout(function () {
      $button.prop('disabled', false).removeClass('button--success').removeClass('button--error');
    }, 3000);
  };

  /**
   * Initialize radio buttons.
   * @private
   */
  var initRadio = function initRadio() {

    $('.radio-option').on('click', function () {

      var $radio = $('.radio', this);
      var $input = $('input[type="radio"]', this);
      var $inputs = $('input[name="' + $input.attr('name') + '"]');

      if (!$radio.hasClass('checked')) {

        $inputs.each(function () {
          $(this).closest('.radio-option').find('.radio').removeClass('checked');
        });

        $input.prop('checked', true);
        $radio.addClass('checked');
      }

      return false;
    });

    return true;
  };

  /**
   * Initialize checkboxes.
   * @private
   */
  var initCheckbox = function initCheckbox() {

    $('.checkbox-option').on('click', function () {

      var $checkbox = $('.checkbox', this);
      var $input = $('input[type="checkbox"]', this);

      if (!$checkbox.hasClass('checked')) {

        $input.prop('checked', true);
        $checkbox.addClass('checked');
      } else {

        $input.prop('checked', false);
        $checkbox.removeClass('checked');
      }

      return false;
    });

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}(jQuery);
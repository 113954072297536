'use strict';

window.site = window.site || {};

/**
 * Init Mobile Menu related scripts
 * @class Site
 * @static
 */
site.Showroom = function Showroom() {
  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  var $showroomLinks = [].slice.call(document.querySelectorAll('#group-name-filters .blocks-nav-link'));
  var $blocks = [].slice.call(document.querySelectorAll('[data-group-name]'));

  /**
   * Select the group
   * @public
   */
  var selectGroup = function selectGroup(group) {
    $blocks.forEach(function ($block) {
      var blockGroup = $block.getAttribute('data-group-name');
      if (group === 'all' || group === blockGroup) {
        $block.classList.remove('is-hidden');
      } else {
        $block.classList.add('is-hidden');
      }
    });
  };

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    if ($showroomLinks && $blocks) {
      $showroomLinks.forEach(function ($link) {
        $link.addEventListener('click', function (e) {
          e.preventDefault();

          // Reset links
          $showroomLinks.forEach(function ($resetLink) {
            $resetLink.classList.remove('is-active');
          });
          $link.classList.add('is-active');

          selectGroup($link.getAttribute('data-group'));
        });
      });

      selectGroup('all');
    }

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();
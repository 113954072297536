'use strict';

window.site = window.site || {};

/**
 * Utilities for interacting with the zoom.
 * @class Menu
 * @static
 */
site.Zoom = function Zoom($) {

  'use strict';

  /**
   * Has the application been initialized?
   * @private
   */

  var inited = false;

  /**
   * jQuery elements.
   * @private
   */
  var $background = $('.publication__header');

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    console.log('[Init zoom.js]');

    $(window).scroll(function () {
      var scroll = $(window).scrollTop();
      $background.css({
        backgroundSize: 100 + scroll / 20 + "%",
        top: -(scroll / 10) + "%"

        //Blur suggestion from @janwagner: https://codepen.io/janwagner/ in comments
        //"-webkit-filter": "blur(" + (scroll/200) + "px)",
        //filter: "blur(" + (scroll/200) + "px)"
      });
    });

    // Return success
    return true;
  };

  /**
   * Expose public methods & properties.
   */
  return {
    init: init
  };
}(jQuery);
'use strict';

window.site = window.site || {};

/**
 * Init Mobile Menu related scripts
 * @class Site
 * @static
 */
site.Grid002 = function Grid002() {
  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;
  var $gridCards = [].slice.call(document.querySelectorAll('.grid002 .grid002-row-element'));

  /**
   * Initializes the class.
   * @public
   */
  var resizeCards = function resizeCards() {
    $gridCards.forEach(function ($card) {
      var $image = $card.querySelector('.grid002-row-img');
      var $content = $card.querySelector('.grid002-row-content');

      $content.style.height = $image.offsetHeight + 'px';
    });
  };

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    if ($gridCards) {
      if (window.innerWidth > 767) {
        window.addEventListener('resize', function () {
          if (window.innerWidth > 767) {
            resizeCards();
          }
        });

        resizeCards();
      }
    }

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();
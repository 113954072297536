'use strict';

window.site = window.site || {};

/**
 * Init Mobile Menu related scripts
 * @class Site
 * @static
 */
site.MenuMobile = function MenuMobile() {

  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    var $trigger = document.querySelector('.mobile-menu-trigger');
    var $triggerClose = document.querySelector('.navigation-close');

    if ($trigger && $triggerClose) {

      $trigger.addEventListener('click', function (e) {
        var $main = document.querySelector('.site-container');
        var $menu = document.querySelector('.navigation-mobile');

        $main.classList.toggle('active');
        $menu.classList.toggle('is-active');
      });

      $triggerClose.addEventListener('click', function (e) {
        var $main = document.querySelector('.site-container');
        var $menu = document.querySelector('.navigation-mobile');

        $main.classList.toggle('active');
        $menu.classList.toggle('is-active');
      });

      // Activate Follow Sticky
      activateMenuFollow();
    };

    return true;
  };

  var activateMenuFollow = function activateMenuFollow() {

    var $nav = document.querySelector('#main-header');
    var buffer = 10;

    var lastScrollTop = 0;

    var st = document.documentElement.scrollTop;

    if (st >= 158) {
      $nav.classList.remove('is-sticky');
    } else {
      $nav.classList.remove('is-sticky');
    }

    window.addEventListener("scroll", function () {

      var st = document.documentElement.scrollTop;

      // console.log(st);

      if (st >= 158) {
        $nav.classList.add('is-sticky');
      } else {
        $nav.classList.remove('is-sticky');
      }

      if (st > lastScrollTop) {
        // downscroll code
        $nav.classList.add("hide-nav-bar");
      } else if (st < lastScrollTop - buffer) {
        // upscroll code
        $nav.classList.remove("hide-nav-bar");
      }

      if (st <= 15) {
        $nav.classList.remove("hide-nav-bar");
      }

      lastScrollTop = st;
    });
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();
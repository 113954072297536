'use strict';

window.site = window.site || {};

/**
 * Utilities for interacting with the map.
 * @class Map
 * @static
 */
site.Map = function Map($) {

  'use strict';

  /**
   * Has the application been initialized?
   * @private
   */

  var inited = false;

  /**
   * Map container.
   * @private
   */
  var $map = $('#map');

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    console.log('[Init map.js]');

    // Initialize map
    if ($map.length) {
      initMap();
    }

    // Return success
    return true;
  };

  /**
   * Initialize map plugin.
   * @private
   */
  var initMap = function initMap() {

    var school = site.App.config('school');

    // Styling
    var styles = [{
      "featureType": "administrative",
      "elementType": "geometry.stroke",
      "stylers": [{
        "weight": "0.70"
      }]
    }, {
      "featureType": "administrative",
      "elementType": "labels.text.fill",
      "stylers": [{
        "color": "#73898d"
      }]
    }, {
      "featureType": "administrative.country",
      "elementType": "geometry.stroke",
      "stylers": [{
        "visibility": "on"
      }, {
        "weight": "0.46"
      }]
    }, {
      "featureType": "landscape",
      "elementType": "all",
      "stylers": [{
        "color": "#f2f2f2"
      }]
    }, {
      "featureType": "landscape",
      "elementType": "geometry.fill",
      "stylers": [{
        "color": "#ffffff"
      }]
    }, {
      "featureType": "poi",
      "elementType": "all",
      "stylers": [{
        "visibility": "off"
      }]
    }, {
      "featureType": "poi.park",
      "elementType": "geometry.fill",
      "stylers": [{
        "color": "#edf7e1"
      }, {
        "visibility": "on"
      }]
    }, {
      "featureType": "road",
      "elementType": "all",
      "stylers": [{
        "saturation": -100
      }, {
        "lightness": 45
      }, {
        "visibility": "simplified"
      }]
    }, {
      "featureType": "road.highway",
      "elementType": "all",
      "stylers": [{
        "visibility": "simplified"
      }]
    }, {
      "featureType": "road.highway",
      "elementType": "geometry.fill",
      "stylers": [{
        "color": school.color_2
      }, {
        "visibility": "simplified"
      }]
    }, {
      "featureType": "road.highway",
      "elementType": "labels.text",
      "stylers": [{
        "color": "#4e4e4e"
      }]
    }, {
      "featureType": "road.arterial",
      "elementType": "geometry.fill",
      "stylers": [{
        "color": "#f4f4f4"
      }]
    }, {
      "featureType": "road.arterial",
      "elementType": "labels.text.fill",
      "stylers": [{
        "color": "#787878"
      }]
    }, {
      "featureType": "road.arterial",
      "elementType": "labels.icon",
      "stylers": [{
        "visibility": "off"
      }]
    }, {
      "featureType": "transit",
      "elementType": "all",
      "stylers": [{
        "visibility": "off"
      }]
    }, {
      "featureType": "water",
      "elementType": "all",
      "stylers": [{
        "visibility": "on"
      }, {
        "color": "#f1f1f1"
      }, {
        "saturation": "-13"
      }]
    }];

    // Map options
    var options = {
      mapTypeControlOptions: {
        mapTypeIds: ['School']
      },
      zoom: 13,
      center: new google.maps.LatLng(school.lat, school.lng),
      mapTypeId: 'School',
      draggable: false,
      scrollwheel: false,
      disableDefaultUI: true
    };

    // Create map
    var map = new google.maps.Map($map[0], options);
    map.mapTypes.set('School', new google.maps.StyledMapType(styles, { name: 'School' }));

    // Create marker
    var svgMarker = {
      path: 'M37.927717,19.1198137 C37.927717,21.6741015 37.4100009,24.1075465 36.4738004,26.3208117 C33.6654101,32.9607657 19.4276905,51.3801598 19.4276905,51.3801598 C19.4276905,51.3801598 5.18997095,32.9607657 2.38152786,26.3208117 C1.44538016,24.1075465 0.927716957,21.6741543 0.927716957,19.1198137 C0.927716957,8.90255712 9.21043391,0.619840169 19.4276905,0.619840169 C29.6449472,0.619840169 37.927717,8.90255712 37.927717,19.1198137 L37.927717,19.1198137 Z M19.4276905,9.94026128 C14.8646244,9.94026128 11.1655279,13.6393577 11.1655279,18.2024239 C11.1655279,22.7654372 14.8646244,26.4645865 19.4276905,26.4645865 C23.9907567,26.4645865 27.6898003,22.7654372 27.6898003,18.2024239 C27.6898003,13.6394106 23.9907567,9.94026128 19.4276905,9.94026128 L19.4276905,9.94026128 Z',
      fillColor: school.color_2,
      fillOpacity: 1,
      scale: 1,
      anchor: new google.maps.Point(20, 52),
      strokeColor: school.color_2,
      strokeWeight: 1
    };

    var marker = new google.maps.Marker({
      map: map,
      url: 'https://maps.google.com/?hl=fr&q=' + encodeURIComponent(school.address + ', ' + school.city + ', Québec, Canada, ' + school.postal),
      icon: svgMarker,
      title: school.title,
      position: new google.maps.LatLng(school.lat, school.lng)
    });

    google.maps.event.addListener(marker, 'click', function () {
      window.open(this.url, '_blank');
    });
  };

  /**
   * Expose public methods & properties.
   */
  return {
    init: init
  };
}(jQuery);
'use strict';

// Globals and dependencies
/* global Vue */
/* global loadjs */

loadjs.ready(['vue'], {
  success: function success() {
    // Define a new component called ScrollLink
    Vue.component('scroll-link', {
      props: ['href'],

      methods: {
        scroll: function scroll() {
          document.querySelector(this.href).scrollIntoView({ behavior: 'smooth' });
        }
      },
      template: '\n        <a :href="href" @click.prevent="scroll">\n          <slot></slot>\n        </a>\n      '
    });
  }
});
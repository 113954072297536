'use strict';

window.site = window.site || {};

/**
 * Init Mobile Menu related scripts
 * @class Site
 * @static
 */
site.Slider002 = function Slider002() {
  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    var $sliders = [].slice.call(document.querySelectorAll('.slider002'));

    if ($sliders.length) {
      setTimeout(function () {
        $sliders.forEach(function ($slider) {
          var $sliderSection = $slider.querySelector('.slider002-carousel');

          var flkty = new Flickity($sliderSection, {
            cellAlign: 'center',
            wrapAround: true,
            prevNextButtons: false,
            pageDots: true,
            imagesLoaded: true,
            adaptiveHeight: true,
            resize: true,
            groupCells: false,
            lazyLoad: true,
            autoPlay: $slider.hasAttribute('data-autoplay')
          });
          flkty.on('staticClick', function (event, pointer, cellElement, cellIndex) {
            if (typeof cellIndex === 'number') {
              flkty.selectCell(cellIndex);
            }
          });
        });
      }, 200);
    }

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();
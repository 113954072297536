'use strict';

// Globals and dependencies
/* global Vue */
/* global loadjs */
/* global _ */ // Loadash

loadjs.ready(['vue'], {
  success: function success() {
    // Define a new component called accordion-item
    Vue.component('make-sticky', {
      mounted: function mounted() {
        // console.log('MainNavComponent mounted();');
        var $element = this.$el;
        var originalOffsetTop = $element.offsetTop;

        /* eslint prefer-arrow-callback: [ "error", { "allowNamedFunctions": true } ] */
        window.addEventListener('scroll', _.throttle(function throttleScroll() {
          // console.log('I just scrolled!');
          if (window.scrollY >= originalOffsetTop) {
            $element.classList.add('is-sticky');
          } else {
            $element.classList.remove('is-sticky');
          }
        }, 300));
      },
      template: '\n        <div><slot></slot></div>\n      '
    });
  }
});